<!--公告管理-->
<template>
    <div class="mainBox">
        <div class=" integralBox table" style="height:calc(100% - 90px);padding:0">
            <div class="flex int_border" style="padding:10px 0;box-sizing:border-box;">
                <div class="flex flex-ai-fs">
                    <label for="">
                        公告内容：
                    </label>
                    <el-input type="textarea" style="width:60%" :rows="4" resize="none" v-model="notice_content"></el-input>
                </div>
            </div>
            <el-button type="primary" size="small" style="margin:50px 0 0 230px;display: block;width:300px" @click="submit">确定</el-button>
        </div>
    </div>
</template>
<script>
    import {noticeReadApi,noticeSaveApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                notice_content:''
            }
        },
        mounted() {
            this.readDetail();
        },
        methods: {
            //公告读取详情
            readDetail:function(){
                noticeReadApi().then(response=>{
                    this.notice_content = response;
                })
            },
            submit:function(){
                noticeSaveApi({
                    notice_content:this.notice_content
                }).then(()=>{
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.readDetail();
                })
            }
        },
    }
</script>